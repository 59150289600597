import {DOMAIN, LANDING_PAGE_SETTINGS, LEADS} from "../../../../../shared/links";
import axios from "axios";

export default {

    getLandingSettings(_, data) {
        let url = LANDING_PAGE_SETTINGS + '?domain=' + data.domain;
        return axios.get(url).then((response) => {
            return response.data;
        });
    },
    getDomain(_, data) {
        let url = DOMAIN.GET_DOMAIN + '?url=' + data.url;
        return axios.get(url).then((response) => {
            return response.data;
        })
    },
    createLead(_, data) {
        let url = LEADS.CREATE_LEAD;
        return axios.post(url, data).then((response) => {
            return response.data;
        });
    },
}
