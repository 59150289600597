import {CUSTOMIZE_REPORT, REPORT} from "../../../../../shared/links";
import axios from "axios";

export default {
    getSettings(_, domain) {
        let url = CUSTOMIZE_REPORT.SETTINGS + '?domain_id=' + domain;
        return axios.get(url).then((response) => {
            return response.data;
        });
    },
    getReport(_, data) {
        let url = REPORT.REPORT + '?&refresh=' + data.refresh + '&token=' + data.token;

        if (data.websiteUrl) {
            url += '&website_url=' + data.websiteUrl;
        }

        return axios.get(url).then((response) => {
            return response.data;
        });
    },
    createReport(_, data) {
        let url = REPORT.REPORT + '?website_url=' + data;

        return axios.post(url).then((response) => {
            return response.data;
        });
    },
    generatePDF(_, data) {
        let url = REPORT.REPORT + '/generatePDF/?domain_id=' + data.domainId + '&refresh=' + data.refresh + '&token=' + data.token + '&phone=' + data.phone + '&email=' + data.email;

        if (data.websiteUrl) {
            url = url + '&website_url=' + data.websiteUrl;
        }

        return axios({url: url,  method: 'POST', responseType: 'blob', data: data}).
        then((response) => {
            return response.data;
        });
    },

    getToken(_, data) {
        let url = REPORT.GET_TOKEN;

        return axios.post(url, data).then((response) => {
            return response.data;
        });

    },

    getOldReportToken(_, data) {
        let url = REPORT.GET_OLD_REPORT_TOKEN + '?website_url=' + data.websiteUrl + '&domain_id=' + data.domainId;

        return axios.get(url, data).then((response) => {
            return response.data;
        });

    }
}
