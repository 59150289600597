import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'landing',
        component: () =>
            import(/* webpackChunkName: "landingPage" */ "../views/Landing"),
        meta: {
            title: 'Get Detailed SEO Audit of your Website Now',
        },
    },
    {
        path: '/report',
        name: 'report',
        component: () =>
            import(/* webpackChunkName: "report" */ "../views/Report/Report"),
        meta: {
            title: 'Single Page Audit Report',
        },
    },
    {
        path: '/report/footer',
        name: 'report.pdf',
        component: () =>
            import(/* webpackChunkName: "report" */ "../views/Report/FooterPdf"),
        meta: {
            title: 'Report PDF Footer',
        },
    },
    {
        path: '*',
        redirect: {name: 'landing'},
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next)=> {
    //set titles for each page
    document.title = to.meta.title;
    return next();

});
export default router;
