export const notificationMixin = {
    methods: {
        showNotifications(data) {
            this.$bvToast.toast(data.message, {
                autoHideDelay: 5000,
                variant: data.type,
            });
        },
        showErrorNotifications(error) {
            if (!error.response) {
                this.$bvToast.toast('Something went wrong please try again', {
                    autoHideDelay: 5000,
                    variant: 'danger',
                });
                return;
            }
             if (error.response.status === 401) {
                return;

            } else if (error.response.status === 404) {
                this.$bvToast.toast('Not Found', {
                    autoHideDelay: 5000,
                    variant: 'danger',
                });
            } else if (error.response.status === 400) {
                this.$bvToast.toast(error.response.data[Object.keys(error.response.data)[0]], {
                    autoHideDelay: 5000,
                    variant: 'danger',
                });

            } else if (error.response.status === 409) {
                this.$bvToast.toast(error.response.data, {
                    autoHideDelay: 5000,
                    variant: 'danger',
                });
            } else if (error.response.status === 422) {
                let messages = [];
                const errorResult = error.response.data.errors;
                for (const key in errorResult) {
                    if (Object.prototype.hasOwnProperty.call(errorResult, key)) {
                        messages.push(errorResult[key].join(', '));
                    }
                }
                this.$bvToast.toast(messages, {
                    autoHideDelay: 5000,
                    variant: 'danger',
                });
            } else if (error.response.status === 403) {

                this.$bvToast.toast(error.response.data, {
                    autoHideDelay: 5000,
                    variant: 'danger',
                });
            } else {
                this.$bvToast.toast('Something went wrong please try again', {
                    autoHideDelay: 5000,
                    variant: 'danger',
                });
            }
        },
    },
}
