import Vue from "vue";
import Vuex from "vuex";

import report from './modules/report';
import landing from './modules/landing';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    report,
    landing
  }
});
