import Vue from "vue";
import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import {notificationMixin} from "../../shared/mixins/notification-mixin";

import VueClipboards from 'vue-clipboards';



Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

Vue.use(VueClipboards);

//Vee Validator
import {
    ValidationObserver,
    ValidationProvider,
    extend,
    localize
} from "vee-validate";

import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";


import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Install VeeValidate rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

localize("en", en);

// Install VeeValidate components globally
Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

Vue.mixin(notificationMixin);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");
