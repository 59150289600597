const API_URL = `${process.env.VUE_APP_BACKEND_URL}/api`;

const TEST = {
    generateUrl,
    GET_TEST_URL: `${API_URL}/test`,
};
const INTEGRATIONS = {
    generateUrl,
    INTEGRATIONS_URL: `${API_URL}/integrations`,
    MAILCHIMP: `${API_URL}/integrations/mailchimp`,
    HUBSPOT: `${API_URL}/integrations/hubspot`,
    MAILCHIMP_AUDIENCE: `${API_URL}/integrations/mailchimp/audiences`,
    CONVERSION_TRACKING: `${API_URL}/integrations/conversion-tracking`,
};

const AUTH = {
    generateUrl,
    LOGIN: `${API_URL}/login`,
    REFRESH: `${API_URL}/login/refresh`,
    LOGOUT: `${API_URL}/logout`,
    SIGN_IN: `${API_URL}/register`,
    RESET_PASSWORD: `${API_URL}/password/reset`,
    FORGOT_PASSWORD: `${API_URL}/forgot`,
    ME: `${API_URL}/me`,
    UPDATE_USER: `${API_URL}/user/update-settings`,
    UPDATE_PASSWORD: `${API_URL}/user/update-password`
};

const LEADS = {
    generateUrl,
    LEADS_URL: `${API_URL}/leads`,
    GET_BLOCKED_LEADS: `${API_URL}/blocked_leads`,
    DOWNLOAD_LEADS: `${API_URL}/leads/export`,
    UPLOAD_LEADS: `${API_URL}/leads/import`,
    GET_SETTINGS: `${API_URL}/lead-settings`,
    CREATE_LEAD: `${API_URL}/lead/create`,
};

const DOMAIN = {
    generateUrl,
    SETTINGS: `${API_URL}/domain-settings/`,
    GET_DOMAIN: `${API_URL}/domain-settings/getDomain`,
    VALIDATE: `${API_URL}/domain-settings/validate`,
};
export const LANDING_PAGE_SETTINGS =`${API_URL}/landing/`;
export const POPUP_SETTINGS =`${API_URL}/popup/`;
export const EMBED_SETTINGS =`${API_URL}/embed/`;

const SETUP_FORM = {
    generateUrl,
    SETTINGS: `${API_URL}/settings/`,
    PROFILE_SETUP: `${API_URL}/user/update-profile-setup`,
    UPLOAD_FILE: `${API_URL}/user/upload`,
};
const CUSTOMIZE_REPORT = {
    generateUrl,
    SETTINGS: `${API_URL}/settings/report`,
    SETTINGS_RESET: `${API_URL}/settings/report/reset`,
    GET_RECOMMENDATIONS: `${API_URL}/settings/language-customization`,
};
const SUBSCRIPTION = {
    generateUrl,
    URL: `${API_URL}/subscription`,
    CARD_DETAILS: `${API_URL}/card`,
    INVOICES: `${API_URL}/invoices`,
    LAST_CHARGE: `${API_URL}/payment_intents`,
    PLANS: `${API_URL}/plans`,
    GENERATE: `${API_URL}/subscription/generate`,
    SYNC: `${API_URL}/subscription/sync`,
    PAYMENT_METHOD: `${API_URL}/subscription/payment-method`,
    SETUP_INTENT: `${API_URL}/setup-intent`
};
const REPORT = {
    generateUrl,
    REPORT: `${API_URL}/report`,
    GET_TOKEN: `${API_URL}/lead/getToken`,
    CHECK_URL: `${API_URL}/urlExist`,
    GET_OLD_REPORT_TOKEN: `${API_URL}/getOldReportToken`,
};

function generateUrl(key, params) {
    let url = this[key];
    Object.keys(params).forEach(param => {
        url = url.replace(`:${param}`, params[param])
    });
    return url;
}

export {
    API_URL,
    TEST,
    AUTH,
    LEADS,
    SETUP_FORM,
    REPORT,
    DOMAIN,
    INTEGRATIONS,
    CUSTOMIZE_REPORT,
    SUBSCRIPTION
}
